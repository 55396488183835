import React from "react";
import loadable from "@loadable/component";
import { Section } from "@atoms";

const DefaultPageContainer = loadable(() =>
  import("@templates/DefaultPageContainer")
);
const Frame = loadable(() => import("@templates/Frame"));
const Hero = loadable(() => import("@organisms/Hero"));
const SectionIntro = loadable(() => import("@organisms/SectionIntro"));
const GrowingImageCopyThumbprint = loadable(() =>
  import("@templates/GrowingImageCopyThumbprint")
);
const GrowingImageSlide = loadable(() =>
  import("@templates/GrowingImageSlide")
);
const StickyPortalImage = loadable(() =>
  import("@organisms/StickyPortalImage")
);
const Audiogram = loadable(() => import("@organisms/Audiogram"));

const DreamPage = ({ data }) => {
  const { title, intro, invitation, move, overview, program } = data;
  return (
    <DefaultPageContainer>
      <Frame
        title={intro?.heading || title}
        next="/can-you-build-it"
        previous="/"
      />
      <Hero {...intro} largerHeadingSpace border />
      <div className="relative z-10 my-12 border-b-3 border-white md:ml-14" />
      {/* invitation */}
      <Section id="invitation">
        <SectionIntro {...invitation} />
        <div className="relative z-10 mt-12 border-b-3 border-white md:ml-14" />
        <GrowingImageCopyThumbprint {...move} />
      </Section>
      {/* promise */}
      <Section id="promise">
        <StickyPortalImage {...move} />
      </Section>
      {/* program */}
      <Section id="program">
        <div className="relative z-10 border-t-3 border-white pb-12 md:ml-14" />
        <SectionIntro {...overview} />
        <div className="relative z-10 my-12 border-b-3 border-white md:ml-14" />
        <GrowingImageSlide {...program} headingAlign="center" />
        <div className="relative z-[100]">
          <Audiogram {...program.audiogram} centeredGram />
        </div>
      </Section>
    </DefaultPageContainer>
  );
};

DreamPage.defaultProps = {};

export default DreamPage;
