import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/DreamIt";
import resolveSideScroll from "@dataResolvers/resolveSideScroll";
import resolveAudiogram from "@dataResolvers/resolveAudiogram";
import resolveImage from "@dataResolvers/resolveImage";
// eslint-disable-next-line no-unused-vars
import AudiogramFragment from "@fragments/AudiogramFragment";
// eslint-disable-next-line no-unused-vars
import SideScrollFragment from "@fragments/SideScrollFragment";
// eslint-disable-next-line no-unused-vars
import ImageFragment from "@fragments/ImageFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query DreamItQuery($siteId: [Craft_QueryArgument]) {
    craft {
      entry(section: "canYouDreamIt", siteId: $siteId) {
        ... on Craft_canYouDreamIt_canYouDreamIt_Entry {
          title
          metaTitle
          metaDescription
          metaImage {
            ...ImageFragment
          }
          # intro
          introHeading: heading0
          introDescriptor: descriptor0
          #  Invitation
          invitationLabel: heading10
          invitationHeading: heading1
          invitationDescriptor: descriptor1
          # We Move
          moveBrand: descriptor2
          moveHeading: heading2
          moveLabel: heading4
          moveStationaryText: heading8
          moveCopy: copy0
          moveHightlight: heading11
          moveImage: image0 {
            ...ImageFragment
          }
          moveBlocks {
            ... on Craft_moveBlocks_block_BlockType {
              uid
              image {
                ...ImageFragment
              }
              descriptor
            }
          }
          # overview
          overviewLabel: heading9
          overviewHeading: heading7
          overviewCopy: copy2
          # program
          programHeading: heading3
          programSideScroll: sideScrollEmbed0 {
            ...SideScrollFragment
          }
          programAudiogram: audiogram {
            ...AudiogramFragment
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry }) => {
  const {
    title,
    // intro
    introHeading,
    introDescriptor,
    // invitation
    invitationLabel,
    invitationHeading,
    invitationDescriptor,
    // move
    moveHeading,
    moveLabel,
    moveBrand,
    moveHightlight,
    moveStationaryText,
    moveCopy,
    moveBlocks,
    moveImage,
    // overview
    overviewLabel,
    overviewHeading,
    overviewCopy,
    // program
    programHeading,
    programSideScroll,
    programAudiogram,
  } = entry;

  const exp = `(${moveHightlight})`;
  const regex = new RegExp(exp);
  const highlightedMoveCopy = moveCopy.replace(
    regex,
    "<span class='text-white'>$1</span>"
  );
  return {
    meta: {
      title,
    },
    title,
    intro: {
      heading: introHeading,
      description: introDescriptor,
    },
    invitation: {
      label: invitationLabel,
      heading: invitationHeading,
      description: invitationDescriptor,
    },
    move: {
      heading: moveHeading,
      copy: highlightedMoveCopy,
      brand: moveBrand,
      label: moveLabel,
      stationaryText: moveStationaryText,
      blocks: moveBlocks.map(b => ({
        ...b,
        image: resolveImage(b.image),
      })),
      image: resolveImage(moveImage),
    },
    overview: {
      label: overviewLabel,
      heading: overviewHeading,
      description: overviewCopy,
    },
    program: {
      heading: programHeading,
      sideScroll: resolveSideScroll(programSideScroll),
      audiogram: resolveAudiogram(programAudiogram),
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="CanYouDreamItQuery"
    {...props}
  />
);

export default Page;
